import { file2Base64 } from "@/services/utils";

export class FileInfo {
  readonly file: File;
  private fileBase64: string | ArrayBuffer;

  public constructor(file: File) {
    this.file = file;
    file2Base64(this.file).then((d) => {
      this.fileBase64 = d;
    });
  }

  get name() {
    return this.file.name;
  }

  get base64() {
    return this.fileBase64;
  }

  /**
   * Return base64 without data:type
   */
  get base64WoDataType() {
    return this.fileBase64.toString().replace(/^data:(.*,)?/, "");
  }

  get fileSizeMbString() {
    const size = (this.file.size / (1024 * 1024)).toFixed(2);
    return `${size}MB`;
  }

  get type() {
    return this.file.type;
  }

  get isImage() {
    return this.type.includes("image");
  }
}
