const errors = new Map([
  [`filename could not be parsed`, `Не удалось обработать название файла`],
  [
    `Not enougth data provided or entity with the same externalId and source already exists`,
    `Уже загружен в систему`,
  ],
  [`NULL_CUSTOMER_REASON`, `Укажите причину возврата`],
]);

export function getErrorText(key: string) {
  return errors.has(key) ? errors.get(key) : key;
}
